<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';
import * as utils from '../../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/sfa/sfaVisitComplete/getSfaVisitCompleteMonthList',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfa_visitManage_monthProgress_list');
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
  },
};
</script>
